<template>
  <div class="container">
    <ion-card mode="ios" class="custom-card animate__animated animate__fadeIn">
      <ion-card-header>
        <ion-card-title>{{ name }}</ion-card-title>
        <ion-card-subtitle>{{ email }}</ion-card-subtitle>
        <ion-card-subtitle>{{ phone }}</ion-card-subtitle>
        <ion-card-subtitle class="address" color="dark">{{
          address
        }}</ion-card-subtitle>
      </ion-card-header>
    </ion-card>

    <div class="click-to-call custom-card animate__animated animate__fadeInUp" v-if="phone">
      <ion-button :href="`tel:${phone}`" mode="ios" expand="block" color="dark">
        <ion-icon :icon="call" />
        <strong style="margin-left: 8px">Deitu telefonoz</strong></ion-button
      >
    </div>

    <ion-fab
      vertical="bottom"
      horizontal="end"
      slot="fixed"
      @click="editClient(id)"
      mode="ios"
    >
      <ion-fab-button>
        <ion-icon :icon="pencil"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </div>
</template>

<script>
import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  modalController,
} from "@ionic/vue";

import { ref, onMounted } from "vue";
import ClientNew from "@/components/ClientNew.vue";
import { pencil, call } from "ionicons/icons";

export default {
  name: "ClientData",
  components: {
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
  },
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const id = ref(null);
    const name = ref(null);
    const email = ref(null);
    const phone = ref(null);
    const address = ref(null);

    onMounted(() => {
      if (props.client) {
        id.value = props.client.id;
        name.value = props.client.name;
        email.value = props.client.email;
        phone.value = props.client.phone;
        address.value = props.client.address;
      }
    });

    const editClient = async () => {
      const modal = await modalController.create({
        component: ClientNew,
        componentProps: {
          client: props.client,
        },
      });
      return modal.present();
    };

    return {
      id,
      name,
      email,
      phone,
      address,
      editClient,
      pencil,
      call,
    };
  },
};
</script>

<style lang="scss">
.container {
  padding: 20px;
}

.click-to-call {
  padding: 15px;
}
ion-card-title {
  margin-bottom: 30px;
}

ion-card-subtitle {
  margin: 10px 0;
  text-transform: inherit;
}
</style>
