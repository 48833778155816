<template>
  <ion-header class="ion-no-border">
    <ion-toolbar mode="md">
      <ion-title v-if="formTitle"
        ><strong>{{ formTitle }}</strong></ion-title
      >
      <ion-title v-else
        ><strong v-if="term">{{ getTerm(term).name }}</strong> #{{
          id
        }}</ion-title
      >
      <ion-buttons slot="end">
        <ion-button @click="closeModal()">
          <ion-icon slot="icon-only" :icon="closeOutline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <div class="notes" style="margin: 20px 20px 0 20px">
    <ion-icon :icon="warningOutline" />
    <span>
      <strong>Oharra:</strong><br />
      Hasteko lana aukeratu. Ostean Lan puntuala den edo ez galdetuko zaizu. Lan
      puntualak data zehatz batekin lotuko dira.</span
    >
  </div>

  <ion-content class="ion-padding">
    <ion-item>
      <ion-label>Aukeratu lana</ion-label>
      <ion-select
        :value="term"
        @ionChange="term = $event.target.value"
        ok-text="Onartu"
        cancel-text="Utzi"
      >
        <ion-select-option
          v-for="term in jobTerms"
          :key="term.id"
          :value="term.id"
        >
          {{ term.name }}
        </ion-select-option>
      </ion-select>
    </ion-item>

    <ion-item v-if="term">
      <ion-label><strong>Lan puntuala da?</strong> </ion-label>
      <ion-toggle
        mode="ios"
        @ionChange="isSpecialDate = !isSpecialDate"
        :checked="isSpecialDate"
      >
      </ion-toggle>
    </ion-item>

    <!-- Puntuala -->
    <ion-item v-if="isSpecialDate && term">
      <ion-label position="floating">Lana egiteko data</ion-label>
      <ion-datetime
        display-format="MM/DD/YYYY"
        min="1994-01-01"
        mode="ios"
        :value="specialDate"
        :max="maxDate"
        @ionChange="specialDate = $event.target.value"
      >
      </ion-datetime>
    </ion-item>

    <!-- Frekuentzia -->
    <ion-item v-if="!isSpecialDate && term">
      <ion-label
        ><strong>{{ getTerm(term).frecuency }} egun.</strong> defektuz.<br />
        Aldatu nahi al duzu?
      </ion-label>
      <ion-toggle
        mode="ios"
        @ionChange="isDefault = !isDefault"
        :checked="!isDefault"
      >
      </ion-toggle>
    </ion-item>

    <ion-item v-if="!isDefault">
      <ion-label position="floating">Frekuentzia</ion-label>
      <ion-input
        :value="frecuency"
        @ionInput="frecuency = $event.target.value"
      ></ion-input>
    </ion-item>

    <ion-item v-if="term">
      <ion-label position="floating">Lanaren oharrak</ion-label>
      <ion-textarea :value="notes" @ionInput="notes = $event.target.value">
      </ion-textarea>
    </ion-item>
  </ion-content>

  <ion-footer class="ion-no-border" style="padding: 15px">
    <ion-toolbar>
      <div class="buttons-footer">
        <ion-button @click="saveHandle" mode="ios" size="medium"
          >Lana gorde
        </ion-button>

        <ion-button
          v-if="id"
          @click="deleteHandle(id)"
          color="danger"
          mode="ios"
          size="medium"
          >Lana ezabatu
        </ion-button>
      </div>

      <messages v-if="toast" :toast="toast" @closeToast="closeToast"></messages>
    </ion-toolbar>
  </ion-footer>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonItem,
  IonButtons,
  IonButton,
  IonLabel,
  IonInput,
  IonTextarea,
  IonIcon,
  modalController,
  loadingController,
} from "@ionic/vue";
import { closeOutline, warningOutline } from "ionicons/icons";
import axios from "axios";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ref, onMounted, watch, computed } from "vue";

import errors from "@/middlewares/errors.js";
import Messages from "@/components/Messages.vue";

export default {
  name: "JobForm",
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonItem,
    IonButtons,
    IonButton,
    IonLabel,
    IonInput,
    IonTextarea,
    IonIcon,
    Messages,
  },
  props: {
    job: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const formTitle = ref(props.title);
    const jobTerms = ref([]);
    const term = ref(null);
    const isDefault = ref(true);
    const isSpecialDate = ref(false);
    const specialDate = ref(null);
    const id = ref(null);
    const clientId = ref(null);
    const frecuency = ref(null);
    const notes = ref(null);
    const toast = ref(null);
    const api = ref(process.env.VUE_APP_API);

    onMounted(() => {
      if (props.job.id) {
        id.value = props.job.id;
        clientId.value = props.job.client_id;
        term.value = props.job.job_term_id;
        frecuency.value = props.job.frecuency;
        notes.value = props.job.notes;
        isDefault.value = props.job.is_default;
        specialDate.value = props.job.special_date;
        if (specialDate.value) isSpecialDate.value = true;
      }
      clientId.value = props.job.client_id;
      jobTerms.value = getJobTerms.value;
    });

    const getClient = computed(() => store.state.getClient);
    const getJobTerms = computed(() => store.state.getJobTerms);

    watch(getClient, () => {
      //modalController.dismiss();
    });

    watch(getJobTerms, (value) => {
      jobTerms.value = value;
    });

    watch(isSpecialDate, (value) => {
      if (!value) {
        specialDate.value = null;
      }
    });

    const getTerm = (val) => {
      return getJobTerms.value.find((res) => {
        if (res.id === val) return res;
      });
    };

    const closeToast = () => {
      toast.value = null;
    };

    const closeModal = async () => {
      return modalController.dismiss();
    };

    const getArchive = async (id) => {
      router.push(`${api.value}jobs-archive/${id}`);
      modalController.dismiss();
    };

    const saveHandle = async () => {
      const loading = await loadingController.create({
        mode: "ios",
      });

      await loading.present();

      let formatted_date = null;
      if (specialDate.value) {
        let current_datetime = new Date(specialDate.value);
        formatted_date =
          current_datetime.getFullYear() +
          "-" +
          ("0" + (current_datetime.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + current_datetime.getDate()).slice(-2) +
          " " +
          ("0" + current_datetime.getHours()).slice(-2) +
          ":" +
          ("0" + current_datetime.getMinutes()).slice(-2) +
          ":" +
          ("0" + current_datetime.getSeconds()).slice(-2);
      }

      let params = {
        id: id.value,
        client_id: clientId.value,
        job_term_id: term.value,
        is_default: isDefault.value,
        special_date: formatted_date,
        frecuency: frecuency.value,
        notes: notes.value,
        completed: false,
      };

      if (!term.value) {
        toast.value = {
          type: "danger",
          message: "Beharrezko eremu guztiak bete",
        };
        loading.dismiss();
        return true;
      }
      if (isSpecialDate.value && !specialDate.value) {
        toast.value = {
          type: "danger",
          message: "Data beharrezkoa da",
        };
        loading.dismiss();
        return true;
      }

      axios
        .post(`${api.value}jobs`, params)
        .then((res) => {
          if (res.data.error) {
            toast.value = {
              type: "danger",
              message: `Erroreak errepasatu: ${errors(res.data.error)}`,
            };
            loading.dismiss();
            return true;
          }

          store.dispatch("_clientReload", clientId.value).then(() => {
            store.dispatch("_jobsReload");
          });

          toast.value = {
            type: "success",
            message: "Lanaren datuak gorde dira",
          };

          loading.dismiss();
        })
        .catch(() => {
          toast.value = {
            type: "danger",
            message: `Zerbitzariko errore bat gertatu da...`,
          };
          loading.dismiss();
        });
    };

    const deleteHandle = async () => {
      let del = confirm("Lan hau ezabatu nahi al duzu?");

      if (!del) return true;

      const loading = await loadingController.create({
        mode: "ios",
      });

      await loading.present();

      axios
        .delete(`${api.value}jobs/${id.value}`)
        .then(() => {
          store.dispatch("_clientReload", clientId.value).then(() => {
            store.dispatch("_jobsReload");
          });

          toast.value = {
            type: "success",
            message: "Bezeroaren datuak gorde dira",
          };
          loading.dismiss();
        })
        .catch(() => {
          toast.value = {
            type: "danger",
            message: `Zerbitzariko errore bat gertatu da...`,
          };
          loading.dismiss();
        });
    };

    return {
      router,
      jobTerms,
      formTitle,
      saveHandle,
      deleteHandle,
      getArchive,
      closeModal,
      closeOutline,
      term,
      isDefault,
      isSpecialDate,
      specialDate,
      id,
      clientId,
      frecuency,
      notes,
      getTerm,
      closeToast,
      warningOutline,
      toast,
    };
  },
};
</script>

<style lang="scss">
.buttons-footer {
  display: flex;
  justify-content: space-between;
  ion-button {
    width: 100%;
  }
}
</style>
