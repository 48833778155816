<template>
  <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <errors
    v-if="archive && !archive.length"
    :options="{
      title: 'Ez dago artxiborik...',
      message: 'Ez duzu bezero honentzako lanik burutu.',
      image: false,
    }"
  />

  <ion-list v-else style="padding-top: 0; padding-bottom: 0;">
    <ion-item
      v-for="item in archive"
      :key="item.id"
      color="transparent"
      lines="full"
    >
      <ion-label>
        <strong>{{ item.job_term.name }}</strong>
        <p v-if="item.notes" class="notes__job">
          <ion-icon slot="start" :icon="chatboxEllipses"></ion-icon>
          {{ item.notes }}
        </p>
      </ion-label>

      <ion-badge slot="end" color="light">
        <strong>{{ item.work_date }}</strong>
      </ion-badge>
    </ion-item>
  </ion-list>

  <ion-infinite-scroll
    @ionInfinite="loadData($event)"
    threshold="100px"
    id="infinite-scroll"
    :disabled="isDisabled"
  >
    <ion-infinite-scroll-content></ion-infinite-scroll-content>
  </ion-infinite-scroll>
</template>

<script>
import {
  IonItem,
  IonLabel,
  IonList,
  IonIcon,
  IonRefresher,
  IonRefresherContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/vue";

import { chatboxEllipses } from "ionicons/icons";
import { useStore } from "vuex";
import { ref, onMounted, computed, watch } from "vue";
import Errors from "@/components/Errors.vue";

export default {
  name: "ClientArchive",
  components: {
    IonItem,
    IonLabel,
    IonList,
    IonIcon,
    IonRefresher,
    IonRefresherContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    Errors,
  },
  props: {
    client: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const getClient = ref(props.client);
    const archive = ref(null);
    const pageNumber = ref(1);
    const isDisabled = ref(false);

    const getArchive = computed(() => store.state.getArchive);

    onMounted(() => {
      archive.value = getArchive.value;
    });

    watch(getArchive, (value) => {
      if (pageNumber.value == 1) archive.value = [];

      value.forEach((res) => {
        archive.value.push(res);
      });
    });

    // const getArchivePromise = () => {
    //   return new Promise((resolve, reject) => {
    //     console.log(pageNumber.value);
    //     axios
    //       .get(
    //         `${api.value}jobs-archive/${getClient.value.id}?page=${pageNumber.value}`
    //       )
    //       .then((res) => {
    //         pageNumber.value++;
    //         return resolve(res.data.result.data);
    //       })
    //       .catch(() => {
    //         return reject(true);
    //       });
    //   });
    // };

    const doRefresh = (ev) => {
      pageNumber.value = 1;
      store
        .dispatch("_getArchive", [getClient.value.id, 1])
        .then(() => {
          ev.target.complete();
          pageNumber.value++;
        })
        .catch(() => {
          isDisabled.value = true;
        });
    };

    const loadData = (ev) => {
      if (pageNumber.value == 1) pageNumber.value = 2;
      store
        .dispatch("_getArchive", [getClient.value.id, pageNumber.value])
        .then(() => {
          ev.target.complete();
          pageNumber.value++;
        })
        .catch(() => {
          isDisabled.value = true;
        });
    };

    return {
      getClient,
      archive,
      doRefresh,
      loadData,
      isDisabled,
      chatboxEllipses,
    };
  },
};
</script>

<style lang="scss">
.notes {
  &__job {
    display: flex;
    align-items: center;
    font-size: 15px;
    ion-icon {
      margin-right: 5px;
    }
  }
}
</style>
