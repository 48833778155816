<template>

  <errors v-if="clientPayments && !clientPayments.length"
    :options="{
      title: 'Ez dago ordainketarik...',
      message: 'Ez duzu bezero honentzako ordainketarik sortu.',
      image: false
    }"
  />

    <ion-list v-if="clientPayments.length" style="padding-top: 0; padding-bottom: 0;">
      <ion-item
        v-for="payment in clientPayments"
        :key="payment.id"
        color="transparent"
        lines="full"
        @click="openModal(payment)"
      >
        <ion-label>
          <strong>{{ payment.name }}</strong>
        </ion-label>

        <ion-chip v-if="payment.paid == 1" color="success">
          <ion-icon :icon="card" v-if="payment.stripe_id != 0"></ion-icon>
          <ion-label
            ><strong>{{ payment.amount }}€</strong></ion-label
          >
        </ion-chip>

        <ion-chip v-else color="danger">
          <ion-label
            ><strong>{{ payment.amount }}€</strong></ion-label
          >
        </ion-chip>
      </ion-item>
    </ion-list>

    <ion-fab
      vertical="bottom"
      horizontal="end"
      slot="fixed"
      @click="newPayment"
      mode="ios"
    >
      <ion-fab-button>
        <ion-icon :icon="cardOutline"></ion-icon>
      </ion-fab-button>
    </ion-fab>

</template>

<script>
import {
  IonItem,
  IonLabel,
  IonList,
  IonIcon,
  modalController,
} from "@ionic/vue";
import { ref } from "vue";
import {
  checkmarkCircle,
  closeCircle,
  cardOutline,
  card,
} from "ionicons/icons";
import PaymentForm from "@/components/forms/PaymentForm.vue";
import Errors from "@/components/Errors.vue";

export default {
  name: "ClientPayments",
  components: {
    IonItem,
    IonLabel,
    IonList,
    IonIcon,
    Errors
  },
  props: {
    payments: {
      type: Object,
      required: true,
    },
    client: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const clientPayments = ref(props.payments);

    const openModal = async (payment) => {
      const modal = await modalController.create({
        component: PaymentForm,
        componentProps: {
          payment: payment
        },
      });
      return modal.present();
    };

    const newPayment = async () => {
      let client = props.client.id;

      let payment = {
        client: client,
        paid: false,
      };
      const modal = await modalController.create({
        component: PaymentForm,
        componentProps: {
          payment: payment
        },
      });
      return modal.present();
    };

    return {
      clientPayments,
      openModal,
      newPayment,
      checkmarkCircle,
      closeCircle,
      cardOutline,
      card,
    };
  },
};
</script>
