<template>
  <errors
    v-if="currentJobs && !currentJobs.length"
    :options="{
      title: 'Ez dago lanik...',
      message: 'Ez duzu bezero honentzako lanik planifikatu.',
      image: false,
    }"
  />

  <ion-list
    v-if="currentJobs.length"
    style="padding-top: 0; padding-bottom: 0;"
  >
    <ion-item
      v-for="job in currentJobs"
      :key="job.id"
      color="transparent"
      lines="full"
      @click="openModal(job)"
    >
      <ion-label>
        <strong>{{ getTerm(job.job_term_id).name }}</strong>
        <ion-icon v-if="job.special_date" :icon="pin" />
      </ion-label>
      <ion-badge slot="end" color="light">
        <template v-if="!job.special_date">
          <strong v-if="job.is_default"
            >{{ getTerm(job.job_term_id).frecuency }} / egun</strong
          >
          <strong v-else>{{ job.frecuency }} / egun</strong>
        </template>
        <template v-else>
          <strong>{{ job.special_date }}</strong>
        </template>
      </ion-badge>

      <ion-button color="secondary" slot="end" mode="ios"
        ><ion-icon :icon="pencil"></ion-icon
      ></ion-button>
    </ion-item>
  </ion-list>

  <ion-fab
    vertical="bottom"
    horizontal="end"
    slot="fixed"
    @click="newJob"
    mode="ios"
  >
    <ion-fab-button>
      <ion-icon :icon="notificationsOutline"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</template>

<script>
import {
  IonItem,
  IonLabel,
  IonList,
  IonBadge,
  modalController,
} from "@ionic/vue";

import { notificationsOutline, pencil, pin } from "ionicons/icons";
import { useStore } from "vuex";
import { ref, computed, watch } from "vue";
import JobForm from "@/components/forms/JobForm.vue";
import Errors from "@/components/Errors.vue";

export default {
  name: "ClientJobs",
  components: {
    IonItem,
    IonLabel,
    IonList,
    IonBadge,
    Errors,
  },
  props: {
    jobs: {
      type: Object,
      required: true,
    },
    client: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const currentJobs = ref(props.jobs);
    const jobTerms = ref(null);

    const getJobTerms = computed(() => store.state.getJobTerms);

    watch(getJobTerms, (value) => {
      jobTerms.value = value;
    });

    const openModal = async (job) => {
      const modal = await modalController.create({
        component: JobForm,
        componentProps: {
          job: job,
        },
      });
      return modal.present();
    };

    const newJob = async () => {
      let job = {
        client_id: props.client.id,
      };

      const modal = await modalController.create({
        component: JobForm,
        componentProps: {
          job: job,
          title: "Lan berria",
        },
      });
      return modal.present();
    };

    const getTerm = (val) => {
      return getJobTerms.value.find((res) => {
        if (res.id === val) return res;
      });
    };

    return {
      currentJobs,
      openModal,
      newJob,
      getTerm,
      jobTerms,
      notificationsOutline,
      pencil,
      pin,
    };
  },
};
</script>
