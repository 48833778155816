<template>
  <ion-page>
    <template v-if="!loading">
      <ion-header class="ion-no-border">
        <ion-toolbar mode="md" class="custom-header">
          <ion-buttons slot="start" @click="router.go(-1)">
            <ion-button>
              <ion-icon slot="icon-only" :icon="arrowBackOutline"></ion-icon>
            </ion-button>
          </ion-buttons>
          <ion-title v-if="client"
            ><strong>{{ client.name }}</strong> #{{ client.id }}</ion-title
          >
        </ion-toolbar>
        <ion-segment
          scrollable="true"
          @ionChange="segmentChanged($event)"
          value="clientdata"
          mode="md"
          color="primary"
        >
          <ion-segment-button value="clientdata">
            <ion-icon :icon="personSharp" />
          </ion-segment-button>
          <ion-segment-button value="jobs">
            <ion-label>Lanak</ion-label>
          </ion-segment-button>
          <ion-segment-button value="payments">
            <ion-label>Ordainketak</ion-label>
          </ion-segment-button>
          <ion-segment-button value="archive">
            <ion-label>Artxiboa</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-header>
      <ion-content color="tertiary">
        <template v-if="client">
          <client-data
            v-if="selectedSegment == 'clientdata'"
            :client="client"
          ></client-data>
          <client-payments
            v-if="selectedSegment == 'payments'"
            :payments="client.payment"
            :client="client"
          ></client-payments>
          <client-jobs
            v-if="selectedSegment == 'jobs'"
            :jobs="client.job"
            :client="client"
          ></client-jobs>
          <client-archive
            v-if="selectedSegment == 'archive'"
            :client="client"
          ></client-archive>
        </template>
      </ion-content>
    </template>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonLabel,
  IonButton,
  IonButtons,
  IonIcon,
  IonSegment,
  IonSegmentButton,
  loadingController,
} from "@ionic/vue";

import { ref, computed, watch, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import {
  personAddOutline,
  arrowBackOutline,
  personSharp,
} from "ionicons/icons";
import ClientPayments from "@/components/ClientPayments.vue";
import ClientData from "@/components/ClientData.vue";
import ClientJobs from "@/components/ClientJobs.vue";
import ClientArchive from "@/components/ClientArchive.vue";

import { NativePageTransitions } from "@ionic-native/native-page-transitions";

export default {
  name: "ClientEdit",
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonLabel,
    IonButton,
    IonButtons,
    IonIcon,
    IonSegment,
    IonSegmentButton,
    ClientPayments,
    ClientData,
    ClientJobs,
    ClientArchive,
  },

  watch: {
    $route(to, from, next) {
      let options = {
        direction: "right",
        duration: 400,
        slowdownfactor: 0,
        slidePixels: 0,
        iosdelay: 0,
        androiddelay: 0,
        fixedPixelsTop: 0,
        fixedPixelsBottom: 0,
      };

      NativePageTransitions.slide(options).then(() => next());
    },
  },
  mounted() {
    let options = {
      direction: "left",
      duration: 400,
      slowdownfactor: 5,
      slidePixels: 0,
      iosdelay: 0,
      androiddelay: 0,
      fixedPixelsTop: 0,
      fixedPixelsBottom: 0,
    };
    NativePageTransitions.slide(options);
  },

  setup() {
    const loading = ref(true);
    const router = useRouter();
    const store = useStore();
    const selectedSegment = ref("clientdata");
    const client = ref(null);

    onMounted(async () => {
      client.value = store.state.getClient;
      loading.value = false;
      // get Archive
      await store.dispatch("_getArchive", [client.value.id, 1]);
    });

    const getStoreClient = computed(() => store.state.getClient);

    watch(getStoreClient, (value) => {
      client.value = null;

      setTimeout(() => {
        client.value = value;
      }, 10);
      loadingController.dismiss();
    });

    const segmentChanged = (ev) => {
      selectedSegment.value = ev.detail.value;
    };

    return {
      loading,
      client,
      router,
      segmentChanged,
      personAddOutline,
      selectedSegment,
      arrowBackOutline,
      personSharp,
    };
  },
};
</script>

<style lang="scss">
@import "@/theme/theme.scss";
.toolbar-title-default {
  box-shadow: none !important;
}
ion-segment {
  background: white;
}

ion-segment-button {
  --indicator-height: 3px !important;
  border-radius: 3px;
}
</style>
